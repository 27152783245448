import logo from './logo.svg';
import './App.css';
import './training/component1'
import Component1 from './training/component1';

function App() {

  return (
    <div class="App-header">
      <div class="rectangle">
        <div class="text">
          LAB 671.DEV
        </div>
      </div>
    </div>
  );
}

export default App;
